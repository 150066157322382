<template>
    <div>
        <font-awesome-icon :icon="icon()" v-for="i in maxNumber" :key="'heart_'+i" class="heart" :style="heartStyle()"/>
    </div>
</template>

<script>
    export default {
        name: "FloatingHearts",
        computed: {
            maxNumber() {
                return Math.floor(screen.width / 40.0);
            }
        },
        methods: {
            icon() {
                return Math.random() < 0.66 ? ['fas', 'heart'] : ['far', 'heart'];
            },
            heartStyle() {
                return {
                    color: this.randomRedShade(),
                    left: Math.floor(Math.random() * 100) + 'vw',
                    'font-size': Math.floor(Math.random() * 90) + 10 + 'px',
                    '--float-duration': (Math.random() * 5) + 5 + 's',
                    '--wobble-duration': (Math.random() * 2) + 1 + 's',
                    'animation-delay': (Math.random() * 7.5) + 's'
                };
            },
            randomRedShade: () => {
                return 'rgb(255, 50, ' + Math.floor(Math.random() * 150) + ')';
            }
        }
    }
</script>

<style scoped>
    .heart {
        position: fixed;
        bottom: -100px;
        animation: float var(--float-duration) infinite linear, wobble var(--wobble-duration) infinite ease-in-out alternate;
    }

    @keyframes float {
        from {
            bottom: -100px;
        }
        to {
            bottom: calc(100vh + 100px);
        }
    }

    @keyframes wobble {
        from {
            transform: rotate(-15deg);
        }
        to {
            transform: rotate(+15deg);
        }
    }
</style>